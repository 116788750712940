<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 配置管理 -->
  <section class="page-container">
    <el-row class="container-box" :gutter="10">
      <el-col :span="6">
        <div class="left-table tableContainer">
          <div class="tool-bar">
            <div class="tool-left">
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="leftDialogVisible = true"
              >
                添加
              </el-button>
              <el-button
                size="small"
                type="warning"
                icon="el-icon-edit"
                @click="getDetail(rowData.id, 'left')"
              >
                编辑
              </el-button>
              <el-button
                size="small"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete('left', rowData.id)"
              >
                删除
              </el-button>
            </div>
          </div>
          <div class="table-data no-page" ref="leftTableContainer">
            <!-- 内页表格数据 -->
            <el-table
              ref="leftTable"
              border
              style="width: 100%"
              :max-height="leftTableHeight"
              :data="leftTableData"
              highlight-current-row
              @row-click="handleRow"
            >
              <el-table-column type="index" width="50" align="center" />
              <el-table-column label="配置名称" align="center">
                <template slot-scope="scope">
                  <p>
                    {{ scope.row.name }} <i class="el-icon-arrow-right"></i>
                  </p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="right-table tableContainer" id="tableContainer">
          <div class="tool-bar">
            <el-form :inline="true" :model="searchForm" class="search-form">
              <el-form-item>
                <el-input
                  size="small"
                  v-model="searchForm.name"
                  placeholder="配置项名称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  @click="handleSearch"
                  icon="el-icon-search"
                >
                  查询
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-plus"
                  @click="rightDialogVisible = true"
                >
                  添加
                </el-button>
                <el-button
                  size="small"
                  type="danger"
                  icon="el-icon-delete"
                  @click="handleDelete('right')"
                >
                  删除
                </el-button>
              </el-form-item>
            </el-form>
            <div class="tool-right">
              <el-tooltip effect="dark" content="刷新">
                <el-button
                  size="small"
                  icon="el-icon-refresh-right"
                  @click="handleRefresh"
                />
              </el-tooltip>
              <el-tooltip effect="dark" content="全屏">
                <el-button size="small" @click="handleFullScreen">
                  <span class="iconfont">
                    {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                  </span>
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <div class="table-data" ref="rightTableContainer">
            <!-- 内页表格数据 -->
            <el-table
              ref="rightTable"
              border
              style="width: 100%"
              :max-height="rightTableHeight"
              :data="rightTableData"
              v-loading="rightTableLoading"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                fixed="left"
              />
              <el-table-column prop="id" label="ID" width="80" align="center" />
              <el-table-column prop="title" label="配置项标题" align="center" />
              <el-table-column prop="sort" label="排序" align="center" />
              <el-table-column prop="remark" label="备注" align="center" />
              <el-table-column label="创建时间" width="210" align="center">
                <template slot-scope="scope">
                  <p>
                    {{
                      (scope.row.creatTime * 1000)
                        | formatTime("YYYY-MM-DD HH:mm:ss")
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="160"
                align="center"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    class="color-primary"
                    type="text"
                    icon="el-icon-edit"
                    @click="getDetail(scope.row.id, 'right')"
                  >
                    编辑
                  </el-button>
                  <el-button
                    class="color-danger"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete('right', scope.row.id)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页控件 -->
          <el-pagination
            background
            layout="total,sizes,prev, pager, next,jumper"
            :current-page="pagination.current"
            :page-size="pagination.size"
            :page-sizes="pagination.sizes"
            :total="pagination.total"
            @size-change="handleSize"
            @current-change="handleCurrent"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 新增/编辑表单弹窗（左侧栏目） -->
    <el-dialog
      :title="(id && '修改配置') || '添加配置'"
      :visible.sync="leftDialogVisible"
      width="420px"
    >
      <el-form
        :model="leftFormData"
        :rules="leftRules"
        ref="leftFormData"
        label-width="84px"
      >
        <el-form-item label="配置名称:" prop="name">
          <el-input v-model="leftFormData.name" placeholder="请输入配置名称" />
        </el-form-item>
        <el-form-item label="配置值:" prop="value">
          <el-input v-model="leftFormData.value" placeholder="请输入配置值" />
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input v-model="leftFormData.sort" placeholder="请选择配置排序" />
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="leftFormData.remark"
            placeholder="请输入配置备注"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle('left')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('left')">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <!-- 新增/编辑表单弹窗（右侧栏目） -->
    <el-dialog
      :title="(id && '修改配置项') || '添加配置项'"
      :visible.sync="rightDialogVisible"
      width="420px"
    >
      <el-form
        :model="rightFormData"
        :rules="rightRules"
        ref="rightFormData"
        label-width="100px"
      >
        <el-form-item label="配置项标题:" prop="name">
          <el-input
            v-model="rightFormData.name"
            placeholder="请输入配置项标题"
          />
        </el-form-item>
        <el-form-item label="配置项值:" prop="value">
          <el-input
            v-model="rightFormData.value"
            placeholder="请输入配置项值"
          />
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            v-model="rightFormData.sort"
            placeholder="请选择配置项排序"
          />
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="rightFormData.remark"
            placeholder="请输入配置项备注"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle('right')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('right')">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
export default {
  name: "configure",
  inject: ["reload"],
  data() {
    return {
      leftTableHeight: 0, //表格最大高度
      rightTableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      leftTableData: [
        {
          id: 1,
          name: "网站配置",
          value: "暂无",
          sort: 1,
          remark: "",
        },
        {
          id: 2,
          name: "微信公众号",
          value: "暂无",
          sort: 1,
          remark: "",
        },
        {
          id: 3,
          name: "微信小程序",
          value: "暂无",
          sort: 1,
          remark: "",
        },
      ], //配置列表数据
      rowData: {
        id: 1,
        title: "网站全称",
        value: "暂无",
        sort: 1,
        remark: "",
        creatTime: 1648792694,
      }, //配置列表高亮数据
      rightTableLoading: false,
      rightTableData: [], //配置项列表数据
      multipleSelection: [], //表格多选储存数据
      pagination: {
        page: 1,
        total: 100,
        current: 1,
        size: 10,
        sizes: [10, 15, 20, 25, 30],
      }, //分页数据
      id: null, //修改数据id
      leftDialogVisible: false,
      leftFormData: {
        sort: 1,
      },
      leftRules: {
        name: [{ required: true, message: "请输入配置名称", trigger: "blur" }],
        value: [{ required: true, message: "请输入配置值", trigger: "blur" }],
        sort: [
          { required: true, message: "请选择配置排序", trigger: "change" },
        ],
      },
      rightDialogVisible: false,
      rightFormData: {
        sort: 1,
      },
      rightRules: {
        name: [
          { required: true, message: "请输入配置项名称", trigger: "blur" },
        ],
        value: [{ required: true, message: "请输入配置项值", trigger: "blur" }],
        sort: [
          { required: true, message: "请选择配置项排序", trigger: "change" },
        ],
      },
    };
  },
  created() {
    // 获取初始化数据
    this.getLeftTableData();
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
      this.$refs["leftTable"].setCurrentRow(this.leftTableData[0]);
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
  },
  methods: {
    /** 获取左侧表格数据 */
    getLeftTableData() {
      /**
       * 请求接口数据
       */

      this.getRightTableData(this.rowData.id);
    },
    /** 获取右侧表格数据 */
    getRightTableData(id) {
      this.rightTableLoading = true;
      //模拟数据
      let data = [
        {
          id: 1,
          title: "网站全称",
          value: "暂无",
          sort: 1,
          remark: "",
          creatTime: 1648792694,
        },
        {
          id: 2,
          title: "网站简称",
          value: "暂无",
          sort: 1,
          remark: "",
          creatTime: 1648792694,
        },
        {
          id: 3,
          title: "网站LOGO",
          value: "暂无",
          sort: 1,
          remark: "",
          creatTime: 1648792694,
        },
        {
          id: 4,
          title: "网站宣传片",
          value: "暂无",
          sort: 1,
          remark: "",
          creatTime: 1648792694,
        },
      ];
      switch (id) {
        case 2:
          data = [
            {
              id: 1,
              title: "AppId",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
            {
              id: 2,
              title: "AppSecret",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
            {
              id: 3,
              title: "微信token",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
            {
              id: 4,
              title: "EncodingAESKey",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
          ];
          break;
        case 3:
          data = [
            {
              id: 1,
              title: "小程序AppId",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
            {
              id: 2,
              title: "小程序AppSecret",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
            {
              id: 3,
              title: "小程序名称",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
            {
              id: 4,
              title: "小程序LOGO",
              value: "暂无",
              sort: 1,
              remark: "",
              creatTime: 1648792694,
            },
          ];
          break;
        default:
          break;
      }
      this.rightTableData = data;
      /**
       * 请求接口数据
       */
      this.rightTableLoading = false;
      this.$forceUpdate();
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      this.getRightTableData(this.rowData.id);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      this.getRightTableData(this.rowData.id);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      this.getRightTableData(this.rowData.id);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["leftTableContainer"]) {
        this.leftTableHeight = this.$refs["leftTableContainer"].offsetHeight;
      }
      if (this.$refs["rightTableContainer"]) {
        this.rightTableHeight = this.$refs["rightTableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 修改前获取详情信息 */
    getDetail(id, dataFrom) {
      this.id = id;
      switch (dataFrom) {
        case "left":
          /**
           * 请求接口获取详情数据
           */
          //请求返回成功后打开弹窗
          this.leftDialogVisible = true;
          break;
        case "right":
          /**
           * 请求接口获取详情数据
           */
          //请求返回成功后打开弹窗
          this.rightDialogVisible = true;
          break;
        default:
          break;
      }
    },
    /** 取消表单编辑 */
    handleCancle(dataFrom) {
      switch (dataFrom) {
        case "left":
          this.leftDialogVisible = false;
          this.leftFormData = {
            sort: 1,
          };
          this.$refs["leftFormData"].clearValidate();
          break;
        case "right":
          this.rightDialogVisible = false;
          this.rightFormData = {
            sort: 1,
          };
          this.$refs["rightFormData"].clearValidate();
          break;
        default:
          break;
      }
    },
    /** 提交表单信息*/
    handleSubmit(dataFrom) {
      switch (dataFrom) {
        case "left":
          this.$refs["leftFormData"].validate((valid) => {
            if (valid) {
              /**
               * 请求接口，修改密码
               */
              if (this.id) {
                // 存在id，执行编辑请求
              } else {
                // 不存在id，执行添加请求
              }
              // 请求后，不论是否成功，调用方法关闭弹窗
              this.handleCancle(dataFrom);
              // 请求成功后，调用方法，更新页面数据
              this.getLeftTableData();
            }
          });
          break;
        case "right":
          this.$refs["rightFormData"].validate((valid) => {
            if (valid) {
              /**
               * 请求接口，修改密码
               */
              if (this.id) {
                // 存在id，执行编辑请求
              } else {
                // 不存在id，执行添加请求
              }
              // 请求后，不论是否成功，调用方法关闭弹窗
              this.handleCancle(dataFrom);
              // 请求成功后，调用方法，更新页面数据
              this.getRightTableData(this.rowData.id);
            }
          });
          break;
        default:
          break;
      }
    },
    /** 删除 */
    handleDelete(dataFrom, id) {
      if (dataFrom == "right") {
        /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
        if (!id && id != 0) {
          if (this.multipleSelection.length > 0) {
            id = this.multipleSelection.map((item) => item.id);
          }
        }
      }
      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        let confirmMsg = `此操作将永久删除该配置${
          (dataFrom == "right" && "项") || ""
        }, 是否继续?`;
        /** 弹窗再次确认操作 */
        this.$confirm(confirmMsg, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(() => {
            /**
             * 请求接口删除数据
             */
            if (dataFrom == "right") {
              // this.$refs['rightTable'].clearSelection(); //删除操作请求失败，清空表格多选

              /** 判断当前页数据是否仅剩1条 */
              if (this.rightTableData.length == 1) {
                this.pagination = {
                  ...this.pagination,
                  page:
                    (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
                };
              }
            }

            this.$message({
              type: "success",
              message: `配置${(dataFrom == "right" && "项") || ""}已删除!`,
            });

            if (dataFrom == "right")
              return this.this.getRightTableData(this.rowData.id);
            this.getLeftTableData();
          })
          /** 取消触发 */
          .catch(() => {
            if (dataFrom == "right") this.$refs["rightTable"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },

    /** 左侧表格点击触发 */
    handleRow(row) {
      this.leftTableData.forEach((item) => {
        if (item.id == row.id) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
      this.rowData = row;

      // 配置切换，重置配置项列表数据
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      this.getRightTableData(row.id);
    },
  },
};
</script>